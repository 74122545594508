jQuery(document).ready(function(jQuery){
    //MOBILE MENU
    jQuery('#navbarNav').on('show.bs.collapse', function () {
        if(jQuery('header').hasClass('menu-closed')) {
            jQuery('header').removeClass('menu-closed');
        }
        jQuery('header').addClass('menu-open');
    });
    jQuery('#navbarNav').on('hide.bs.collapse', function () {
        if(jQuery('header').hasClass('menu-open')) {
            jQuery('header').removeClass('menu-open');
        }
        jQuery('header').addClass('menu-closed');
    });

    //MOBILE SUBMENUS
	jQuery('#menu-main-menu .menu-item-has-children').click(function() {
		//Handle the menu clicked on
		if(jQuery(this).hasClass('open')) {
			jQuery(this).children('ul').slideUp('fast');
			jQuery(this).removeClass('open');
		} else {
			jQuery(this).addClass('open');		
			jQuery(this).children('ul').slideDown('fast');
		}
		
		//close all other menus		
		var openMenus = jQuery(this).siblings().find('.sub-menu');
		openMenus.each(function() {
			jQuery(this).slideUp('fast');
			jQuery(this).parent().removeClass('open');
		});
    });
    
    //STICKY HEADER
    jQuery(function() {
        var sticky = jQuery('.stick-this');
        jQuery(window).scroll(function() {
            var scroll = jQuery(window).scrollTop();
            if (scroll >= 60) {
                sticky.addClass('stuck');
            } else {
                sticky.removeClass('stuck');
            }
        });
    });

    //Prevent Page Jump
    jQuery('.menu-item-has-children a[href="#"]').click(function(e) {
        e.preventDefault();
    });

    // Elements to inject
    var elementsToInject = document.querySelectorAll('svg[data-src]');
    // Options
    var injectorOptions = {
        evalScripts: 'once',
        pngFallback: 'image/fallback'
    };
    // Trigger the injection
    var injector = new SVGInjector(injectorOptions);
    injector.inject(
        elementsToInject
    );
});